import React from "react";
import rentACar from '../../assets/images/wm_rent_a_car_alquile_de_autos.svg'
import useAppLocation from "../../hooks/useAppLocation";
import {useAuthentication} from "../../hooks/authenticationHooks";

const Header = () => {
    const {activePath} = useAppLocation()
    const {user} = useAuthentication()
    return <header className="header flex-row flex-middle size-small text-gray text-lighten-2">
        <div className="header-left p-l p-r flex-column flex-1">
            <div className="flex-row flex-middle">
                <h2 className="line-height-small">{activePath?.title}</h2>
            </div>
        </div>
        <div className="header-right p-l p-r flex-column">
            <img className="brand" src={rentACar} alt=""/>
        </div>
    </header>
}

export default Header
