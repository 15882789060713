import React from "react";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

import {IDocument, IDocumentMeta} from "../store/api/services/documentsApi";
import PDFTemplateComponent from "../components/global/PDFTemplateComponent";
import {ButtonComponent} from "../components/global/ButtonComponent";
import {SvgImport} from "../utils/ImageUtils";
import downloadIcon from "../assets/images/download.svg";
import emailIcon from "../assets/images/email.svg";
import {useAppDispatch, useAppSelector} from "../store/storeHooks/hooks";
import leftIcon from "../assets/images/left.svg";
import {useNavigate} from "react-router-dom";
import {APP_ROUTES} from "../constants/appRoutes";
import {TooltipComponent} from "../components/global/TooltipComponent";
import {sendMessage} from "../utils/formUtil";
import {addAlertItem, IAlertsObjectType, removeAlertItem} from "../store/slices/alertsSlice";
import {snapShotDocumentMetaSubscription} from "../firebase/documentMetaFirebase";
import {snapShotDocumentSubscription} from "../firebase/documentFirebase";

const DocumentsPreview = () => {
    const [docData, setDocData] = React.useState<IDocument | null>(null)
    const dispatch = useAppDispatch()
    const [docMetaData, setDocMetaData] = React.useState<IDocumentMeta | null>(null)
    const navigate = useNavigate()
    const account = useAppSelector((state) => state.account.activeUserAccount)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const documentId = urlSearchParams.get('document')
    const back = () => {
        navigate(APP_ROUTES.DOCUMENTS.path)
    }
    const sendEmail = () => {
        sendMessage('documents', {user: docData?.user.name, email: docData?.user.email, documentId})
        const alert: IAlertsObjectType = {
            title: 'Mensaje enviado',
            value: 'El documento ha sido enviado con éxito',
            type: 'success'
        }
        dispatch(addAlertItem(alert))
        setTimeout(() => {
            dispatch(removeAlertItem(alert))
        }, 4000)
    }
    React.useEffect(() => {
        let snapshotDocumentSubscription: any = snapShotDocumentSubscription(documentId || '', (documentData) => {
            setDocData(documentData)
        })
        let snapshotDocumentMetaSubscription: any = snapShotDocumentMetaSubscription(documentId || '', (documentData) => {
            setDocMetaData(documentData)
        })
        return () => {
            snapshotDocumentSubscription()
            snapshotDocumentMetaSubscription()
        }
    }, [])

    const componentePdf = docData && docMetaData ? <PDFTemplateComponent docData={docData} docMetaData={docMetaData} /> : null

    return <div className="document-preview">
        <div className="width-100">
            <div className="flex-row flex-end">
                {account.token &&
                    <>
                    <div className="flex-column flex-1 flex-start">
                        <div className="flex-row">
                            <TooltipComponent content={<small className="align-center">Regresar</small>} position="right" maxWidth={68} >
                                <a
                                    onClick={back}
                                    className="icon-24x radius-small bg-gray bg-lighten-6 flex-column flex-middle flex-center icon-flex"
                                >
                                    <SvgImport icon={leftIcon} className="icon-16x icon-flex fill-success flex-row" />
                                </a>
                            </TooltipComponent>
                        </div>
                    </div>
                    <div className="p-b">
                        <ButtonComponent
                            onClick={sendEmail}
                            buttonText="Notificar por email"
                            smallButton={true}
                            iconButton={<SvgImport icon={emailIcon} className="icon-16x m-l fill-white" />}
                        />
                    </div>
                    </>
                }
                <div className="p-l-s p-b">
                    {componentePdf ?
                        <PDFDownloadLink document={componentePdf} fileName="contrato-wm">
                            <ButtonComponent
                                formButton={true}
                                buttonText="Descargar PDF"
                                smallButton={true}
                                iconButton={<SvgImport icon={downloadIcon} className="icon-16x m-l fill-white"/>}
                            />
                        </PDFDownloadLink>
                        :
                        null
                    }
                </div>
            </div>
            <div className="pdf-container">
                {componentePdf ?
                    <PDFViewer width="100%" height="100%">
                        {componentePdf}
                    </PDFViewer>
                    :
                    <div className="text-gray text-lighten-2 light size-3 align-center">
                        No hemos encontrado la información solicitada
                    </div>
                }
            </div>
        </div>
    </div>
}

export default DocumentsPreview