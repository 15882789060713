import {doc, onSnapshot} from "firebase/firestore";
import {firestoreApp} from "../config/fireBaseConfig";
import {IDocument} from "../store/slices/documentsSlice";
import {IDocumentMeta} from "../store/api/services/documentsApi";

const firestoreDocumentMetaReference = (id: string) => doc(firestoreApp, `documentsmeta/${id}`)

export const snapShotDocumentMetaSubscription = (documentId: string, onLoadAction: (document: IDocumentMeta) => void) => onSnapshot(
    firestoreDocumentMetaReference(documentId),
    (docSnapshot) => {
        console.log(docSnapshot)
    const documentMetaResponse = docSnapshot?.data() as IDocumentMeta
    onLoadAction(documentMetaResponse)
})
