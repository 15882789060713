import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IUserAccount {
  email: string
  id: string
  name?: string
}

export interface IActiveUserAccount {
  token: string
  user: IUserAccount
}

export interface IAccountReducer {
  activeUserAccount: IActiveUserAccount
}

const defaultUser: IUserAccount = {
  email: '',
  id: '',
  name: '',
}

export const defaultAccount: IActiveUserAccount = {
  token: '',
  user: { ...defaultUser },
}

const initialState: IAccountReducer = {
  activeUserAccount: { ...defaultAccount },
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setActiveUser: (state, action: PayloadAction<IActiveUserAccount>) => {
      state.activeUserAccount = action.payload
    },
    setUserInformation: (state, action: PayloadAction<IActiveUserAccount>) => {
      state.activeUserAccount.user = action.payload.user
    },
    logout: (state) => {
      state.activeUserAccount = defaultAccount
    },
  },
})

export const { setActiveUser, logout, setUserInformation } =
  accountSlice.actions

export default accountSlice.reducer
