import {collection, onSnapshot, orderBy, query} from "firebase/firestore";
import {firestoreApp} from "../config/fireBaseConfig";
import {IDocument, ILocalDocuments, setDocuments} from "../store/slices/documentsSlice";

const documentsQuery = query(
    collection(firestoreApp, 'documents'),
    orderBy('contractDate')
)

export const snapShotDocumentsSubscription = (dispatch: any) => onSnapshot(documentsQuery, (snapshot) => {
    const documentsResponse: ILocalDocuments[] = []
    snapshot.forEach((doc) => {
        const documentDetails = doc.data() as IDocument
        documentsResponse.push({
            id: doc.id,
            ...documentDetails
        })
    });
    dispatch(setDocuments(documentsResponse))
})
