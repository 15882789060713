import React from "react";

import {ButtonComponent} from "../components/global/ButtonComponent";
import addIcon from '../assets/images/add.svg'
import {SvgImport} from "../utils/ImageUtils";
import {useAppDispatch, useAppSelector} from "../store/storeHooks/hooks";
import {setModalComponent} from "../store/slices/modalSlice";
import {IUserLocalUser} from "../store/slices/usersSlice";
import {TableComponent} from "../components/global/TableComponent";
import editIcon from '../assets/images/edit.svg'
import {snapShotUserSubscription} from "../firebase/userdFirebase";

const Users = () => {
    const dispatch = useAppDispatch()
    const users = useAppSelector(state => state.users.userList)
    const userModal = (user?: IUserLocalUser) => {
        dispatch(setModalComponent({
            component: 'USER',
            modalSize: 'SMALL',
            directProps: {user: user || null}
        }))
    }
    const labels: any = [
        {label: 'NOMBRES', id: 'name', width: 20, typeWidth: '%'},
        {label: 'APELLIDOS', id: 'lastname', width: 20, typeWidth: '%'},
        {label: 'EMAIL', id: 'email', width: 20, typeWidth: '%'},
        {label: 'CI/PASAPORTE', id: 'document', width: 15, typeWidth: '%'},
        {label: 'TELÉFONO', id: 'phone', width: 15, typeWidth: '%'},
        {label: '', id: 'action', width: 10, typeWidth: '%'},
    ]

    console.log('users::::::::', users)

    const tableColumns: any = users.map(item => {
        return {
            id: item.id,
            columns: [
                {id: 'name', value: <strong className="text-gray">{item.name}</strong>, type: 'custom'},
                {id: 'lastname', value: <strong className="text-gray">{item.lastname}</strong>, type: 'custom'},
                {id: 'email', value: item.email, type: 'string'},
                {id: 'document', value: item.document, type: 'string'},
                {id: 'phone', value: item.phone, type: 'string'},
                {
                    id: 'action',
                    value: <div className="flex-row flex-end">
                        <a
                            onClick={() => userModal(item)}
                            className="icon-24x radius-small bg-gray bg-lighten-6 flex-column flex-middle flex-center icon-flex"
                        >
                            <SvgImport icon={editIcon} className="icon-16x icon-flex fill-success flex-row" />
                        </a>
                    </div>,
                    type: 'custom'
                },
            ]
        }
    })
    React.useEffect(() => {
        const snapShotSubscription = snapShotUserSubscription(dispatch)
        return () => snapShotSubscription()
    }, [])

    return <div>
        <div className="flex-row flex-end">
            <ButtonComponent
                buttonText="Agregar usuario"
                smallButton={true}
                iconButton={<SvgImport icon={addIcon} className="icon-16x m-l fill-white" />}
                onClick={() => userModal()}
            />
        </div>
        <div className="width-100 p-t-lg">
            <TableComponent tableRows={tableColumns} labelProps={labels} responsiveStyle="CARD" />
        </div>
    </div>
}

export default Users