import React from "react";

import {ModalLayoutComponent} from "../components/modals/ModalLayoutComponent";
import {InputComponent} from "../components/global/InputComponent";
import {SvgImport} from "../utils/ImageUtils";
import userIcon from '../assets/images/user.svg'
import brandIcon from '../assets/images/brand.svg'
import carIcon from '../assets/images/car.svg'
import identificationIcon from '../assets/images/identification.svg'
import motorIcon from '../assets/images/motor.svg'
import plateIcon from '../assets/images/plate.svg'
import chasisIcon from '../assets/images/chasis.svg'
import yearIcon from '../assets/images/year.svg'
import moneyIcon from '../assets/images/money.svg'
import colorIcon from '../assets/images/color.svg'
import bankIcon from '../assets/images/bank.svg'
import accountnumberIcon from '../assets/images/accountnumber.svg'
import {validateValues} from "../utils/ValidationUtils";
import {IUpdateUser, useUpdateUsersMutation} from "../store/api/services/userApi";
import {SelectComponent} from "../components/global/SelectComponent";
import {useAppDispatch, useAppSelector} from "../store/storeHooks/hooks";
import DateRangeComponent from "../components/global/DateRangeComponent";
import DateComponent from "../components/global/DateComponent";
import {CheckboxComponent} from "../components/global/CheckboxComponent";
import RadioButtonComponent from "../components/global/RadioButtonComponent";
import {getRandomId} from "../utils/StringUtils";
import {
    IDocument,
    IDocumentMeta,
    IUserData,
    useCreateDocumentMetaMutation,
    useCreateDocumentMutation,
    useUpdateDocumentMetaMutation,
    useUpdateDocumentMutation
} from "../store/api/services/documentsApi";
import {ILocalDocuments} from "../store/slices/documentsSlice";
import {DAYS} from "../constants/datesConstants";
import {snapShotUserSubscription} from "../firebase/userdFirebase";
import {snapShotDocumentMetaSubscription} from "../firebase/documentMetaFirebase";
import {CITIES_CONSTANTS} from "../constants/citiesConstants";

interface IUserDocument {
    directProps?: {
        document?: ILocalDocuments
    }
}

const UserDocumentModal = (props: IUserDocument) => {
    const { directProps } = props
    const [createAt, handleCreateAt] = React.useState(new Date().toDateString())
    const [userDocumentId, handleUserDocumentId] = React.useState<string | null>(null)
    const [userData, handleUserData] = React.useState({
        user: null,
        contractDate: '',
        extraDriverNames: '',
        extraDriverDocument: '',
        vehicleBrand: '',
        vehicleModel: '',
        vehicleMotor: '',
        vehicleChassis: '',
        vehiclePlate: '',
        vehicleColor: '',
        vehicleYear: '',
        rentFromToDate: null,
        dailyPrice: '',
        totalPrice: '',
        controlTravel: false,
        warranty: '',
        warrantyType: '',
        checkNumber: '',
        checkBank: '',
        checkBankAccount: '',
        restrictionDay: '',
        city: '',
        hideSignature: false,
    })
    const users = useAppSelector(state => state.users.userList)
    const dispatch = useAppDispatch()
    const [userErrors, setUserErrors] = React.useState<any>({})
    const [createDocument] = useCreateDocumentMutation()
    const [createDocumentMeta] = useCreateDocumentMetaMutation()
    const [updateDocumentMeta] = useUpdateDocumentMetaMutation()
    const [updateDocument] = useUpdateDocumentMutation()
    const [updateUser] = useUpdateUsersMutation()

    const createUserDocument = () => {
        const uniqueDocumentId = getRandomId()
        const user = users.find(user => user.id === userData.user)
        const userDocuments = user?.documents || []
        const userDocument: IUserData = {
            id: user?.id || '',
            name: user?.name || '',
            phone: user?.phone || '',
            lastname: user?.lastname || '',
            document: user?.document || '',
            email: user?.email || '',
        }
        let updateUserData = {
            ...user,
            documents: userDocumentId ? userDocuments : [...userDocuments, uniqueDocumentId]
        }
        delete updateUserData['id']
        const document: IDocument = {
            user: userDocument,
            createAt,
            city: userData.city || '',
            contractDate: userData.contractDate || '',
            rentFromToDate: {
                // @ts-ignore
                startDate: userData.rentFromToDate?.startDate,
                // @ts-ignore
                endDate: userData.rentFromToDate?.endDate,
                // @ts-ignore
                key: userData?.rentFromToDate?.key || 'selection'
            },
        }
        const documentMeta: IDocumentMeta = {
            extraDriverNames: userData.extraDriverNames,
            extraDriverDocument: userData.extraDriverDocument,
            vehicleBrand: userData.vehicleBrand,
            vehicleModel: userData.vehicleModel,
            vehicleMotor: userData.vehicleMotor,
            vehicleChassis: userData.vehicleChassis,
            vehiclePlate: userData.vehiclePlate,
            vehicleColor: userData.vehicleColor,
            vehicleYear: userData.vehicleYear,
            dailyPrice: userData.dailyPrice,
            totalPrice: userData.totalPrice,
            controlTravel: userData.controlTravel,
            warranty: userData.warranty,
            warrantyType: userData.warrantyType,
            checkNumber: userData.checkNumber || '',
            checkBank: userData.checkBank || '',
            checkBankAccount: userData.checkBankAccount || '',
            restrictionDay: userData.restrictionDay || '',
            hideSignature: userData.hideSignature || false,
        }
        if (userDocumentId) {
            updateDocumentMeta({documentMeta, documentId: userDocumentId})
            updateDocument({document, documentId: userDocumentId})
        } else {
            createDocument({document, documentId: uniqueDocumentId})
            createDocumentMeta({documentMeta, documentId: uniqueDocumentId})
        }
        updateUser({user: updateUserData as IUpdateUser, userId: user?.id || ''})
    }

    const handleUser = () => {
        const requireFields = [
            {key: 'user', message: 'El campo usuario es requerido', type: 'NOT_EMPTY'},
            {key: 'contractDate', message: 'El campo fecha del contrato es requerido', type: 'NOT_EMPTY'},
            {key: 'extraDriverNames', message: 'El campo conductor extra es requerido', type: 'NOT_EMPTY'},
            {key: 'extraDriverDocument', message: 'El documento extra es requerido', type: 'NOT_EMPTY'},
            {key: 'vehicleBrand', message: 'El campo marca es requerido', type: 'NOT_EMPTY'},
            {key: 'vehicleModel', message: 'El campo modelo es requerido', type: 'NOT_EMPTY'},
            {key: 'vehicleMotor', message: 'El campo motor es requerido', type: 'NOT_EMPTY'},
            // {key: 'vehicleChassis', message: 'El campo chasis es requerido', type: 'NOT_EMPTY'},
            {key: 'vehiclePlate', message: 'El campo placa es requerido', type: 'NOT_EMPTY'},
            {key: 'vehicleColor', message: 'El campo color es requerido', type: 'NOT_EMPTY'},
            {key: 'vehicleYear', message: 'El campo año es requerido', type: 'NOT_EMPTY'},
            {key: 'rentFromToDate', message: 'El campo desde/hasta es requerido', type: 'CONTENT'},
            {key: 'dailyPrice', message: 'El campo precio diario es requerido', type: 'NOT_EMPTY'},
            {key: 'totalPrice', message: 'El campo precio total es requerido', type: 'NOT_EMPTY'},
            {key: 'warranty', message: 'El campo garantía total es requerido', type: 'NOT_EMPTY'},
            {key: 'warrantyType', message: 'El campo tipo de garantía es requerido', type: 'NOT_EMPTY'},
            {key: 'restrictionDay', message: 'El campo pico y placa es requerido', type: 'NOT_EMPTY'},
            {key: 'city', message: 'El campo ciudad es requerido', type: 'NOT_EMPTY'},
        ]
        const result = validateValues(userData, requireFields as any)
        if (result.haveErrors){
            console.log('result', result)
            setUserErrors(result.errors)
        } else {
            createUserDocument()
            console.log('envio')
        }
    }
    const handleChange = (value: any, key: string) => {
        handleUserData({...userData, [key]: value})
        const newErrors = {...userErrors}
        delete newErrors[key]
        setUserErrors(newErrors)
    }
    React.useEffect(() => {
        let snapShotSubscription: any = null
        if (directProps?.document) {
            snapShotSubscription = snapShotDocumentMetaSubscription(directProps.document.id, (documentData) => {
                handleUserData({
                    extraDriverNames: documentData.extraDriverNames,
                    extraDriverDocument: documentData.extraDriverDocument,
                    vehicleBrand: documentData.vehicleBrand,
                    vehicleModel: documentData.vehicleModel,
                    vehicleMotor: documentData.vehicleMotor,
                    vehicleChassis: documentData.vehicleChassis,
                    vehiclePlate: documentData.vehiclePlate,
                    vehicleColor: documentData.vehicleColor,
                    vehicleYear: documentData.vehicleYear,
                    dailyPrice: documentData.dailyPrice,
                    totalPrice: documentData.totalPrice,
                    controlTravel: documentData.controlTravel,
                    warranty: documentData.warranty,
                    warrantyType: documentData.warrantyType,
                    checkNumber: documentData.checkNumber || '',
                    checkBank: documentData.checkBank || '',
                    checkBankAccount: documentData.checkBankAccount || '',
                    restrictionDay: documentData.restrictionDay || '',
                    hideSignature: documentData.hideSignature || false,
                    city: directProps?.document?.city || '',
                    // @ts-ignore
                    rentFromToDate: directProps?.document?.rentFromToDate
                        ? {startDate: directProps?.document?.rentFromToDate.startDate, endDate: directProps?.document?.rentFromToDate.endDate, key: directProps?.document?.rentFromToDate.key}
                        : null,
                    // @ts-ignore
                    contractDate: directProps?.document?.contractDate ? directProps?.document?.contractDate : null,
                    user: directProps?.document?.user.id as any
                })
                handleUserDocumentId(directProps?.document?.id || null)
                handleCreateAt(directProps?.document?.createAt || '')
            })
        }
        return () => {
            if (snapShotSubscription) {
                snapShotSubscription()
            }
        }
    }, [])
    React.useEffect(() => {
        const snapShotSubscription = snapShotUserSubscription(dispatch)
        return () => snapShotSubscription()
    }, [])
    return <ModalLayoutComponent
        title={userDocumentId ? "Actualizar documento" : "Crear documento"}
        successAction={handleUser}
        successActionText={userDocumentId ? "actualizar documento" : "crear documento"}
        closeOnCancel={true}
    >
        <div className="p-l-s p-r-s">
            <div className="container container-flex">
                <div className="row">
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <SelectComponent
                                valueId="user"
                                readOnly={!!userDocumentId}
                                value={userData.user}
                                onChangeValue={handleChange}
                                label="Usuario del Contrato"
                                placeholder="Usuario al que está asignado"
                                error={userErrors.user}
                                options={users.map((user) => ({id: user.id, label: `${user.name} ${user.lastname} (ci: ${user.document})`}))}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <SelectComponent
                                valueId="city"
                                value={userData.city}
                                onChangeValue={handleChange}
                                label="Ciudad"
                                placeholder="Ciudad donde se realiza el alquiler"
                                error={userErrors.city}
                                options={Object.values(CITIES_CONSTANTS).map((city) => ({id: city.id, label: city.label}))}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <DateComponent
                                label="Fecha del contrato"
                                valueId="contractDate"
                                value={userData.contractDate}
                                onChange={handleChange}
                                error={userErrors.contractDate}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <DateRangeComponent
                                label="Periodo contratado"
                                valueId="rentFromToDate"
                                changeSelectDate={handleChange}
                                selectDate={userData?.rentFromToDate || undefined}
                                error={userErrors.rentFromToDate}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="extraDriverNames"
                                value={userData.extraDriverNames}
                                onChangeValue={handleChange}
                                label="Conductor Extra"
                                placeholder="Ejm: Pedro Jimenez"
                                icon={<SvgImport icon={userIcon} className="icon-16x" />}
                                error={userErrors.extraDriverNames}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="extraDriverDocument"
                                value={userData.extraDriverDocument}
                                onChangeValue={handleChange}
                                label="CI/PAsaporte conductor extra"
                                placeholder="Ejm: 1100117755"
                                error={userErrors.extraDriverDocument}
                                icon={<SvgImport icon={identificationIcon} className="icon-16x" />}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <SelectComponent
                                valueId="restrictionDay"
                                value={userData.restrictionDay}
                                onChangeValue={handleChange}
                                label="Pico y Placa"
                                placeholder="Día de restricción vehicular"
                                error={userErrors.restrictionDay}
                                options={Object.values(DAYS).map((day) => ({id: day.label, label: day.label}))}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="vehicleBrand"
                                value={userData.vehicleBrand}
                                icon={<SvgImport icon={brandIcon} className="icon-16x" />}
                                onChangeValue={handleChange}
                                label="Marca del vehículo"
                                placeholder="Ejm: Chevrolet"
                                error={userErrors.vehicleBrand}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="vehicleModel"
                                value={userData.vehicleModel}
                                onChangeValue={handleChange}
                                label="Modelo del vehículo"
                                placeholder="Ejm: sail"
                                icon={<SvgImport icon={carIcon} className="icon-16x" />}
                                error={userErrors.vehicleModel}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="vehicleMotor"
                                value={userData.vehicleMotor}
                                onChangeValue={handleChange}
                                label="Motor del vehículo"
                                placeholder="Ejm: v8 4500 CC"
                                icon={<SvgImport icon={motorIcon} className="icon-16x" />}
                                error={userErrors.vehicleMotor}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="vehicleChassis"
                                value={userData.vehicleChassis}
                                onChangeValue={handleChange}
                                label="Chasís del vehículo"
                                placeholder="Ejm: ECT450000000"
                                icon={<SvgImport icon={chasisIcon} className="icon-16x" />}
                                error={userErrors.vehicleChassis}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="vehiclePlate"
                                value={userData.vehiclePlate}
                                onChangeValue={handleChange}
                                label="Placa del vehículo"
                                placeholder="Ejm: PCC-0000"
                                icon={<SvgImport icon={plateIcon} className="icon-16x" />}
                                error={userErrors.vehiclePlate}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="vehicleColor"
                                value={userData.vehicleColor}
                                onChangeValue={handleChange}
                                label="Color del vehículo"
                                placeholder="Ejm: Blanco"
                                icon={<SvgImport icon={colorIcon} className="icon-16x" />}
                                error={userErrors.vehicleColor}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="vehicleYear"
                                value={userData.vehicleYear}
                                onChangeValue={handleChange}
                                label="Año del vehículo"
                                placeholder="Ejm: 2022"
                                icon={<SvgImport icon={yearIcon} className="icon-16x" />}
                                error={userErrors.vehicleYear}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="dailyPrice"
                                value={userData.dailyPrice}
                                onChangeValue={handleChange}
                                label="Precio Diario"
                                placeholder="Ejm: 65"
                                icon={<SvgImport icon={moneyIcon} className="icon-16x" />}
                                error={userErrors.dailyPrice}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="totalPrice"
                                value={userData.totalPrice}
                                onChangeValue={handleChange}
                                label="Precio Total"
                                placeholder="Ejm: 140"
                                icon={<SvgImport icon={moneyIcon} className="icon-16x" />}
                                error={userErrors.totalPrice}
                            />
                        </div>
                    </div>
                    <div className="col-s12 m-b">
                        <div className="flex-1">
                            <CheckboxComponent
                                label="Kilometraje Controlado"
                                options={[{label: 'Si incluye kilometraje controlado', id: '2'}]}
                                valueId="controlTravel"
                                values={userData.controlTravel ? ['2'] : []}
                                onChangeValues={(value) => handleChange(!!value.length, 'controlTravel')}
                            />
                        </div>
                    </div>
                    <div className="col-s12 m-b">
                        <div className="flex-1">
                            <CheckboxComponent
                              label="Ocultar firma electrónica"
                              valueId="hideSignature"
                              options={[{label: 'Ocultar', id: '1'}]}
                              values={userData.hideSignature ? ['1'] : []}
                              onChangeValues={() => handleChange(
                                !userData.hideSignature,
                                'hideSignature'
                              )}
                            />
                        </div>
                    </div>
                    <div className="col-s12 m-b">
                        <div className="flex-1">
                            <RadioButtonComponent
                                label="Tipo de garantía"
                                options={[
                                    {label: 'Tarjeta de crédito', id: 'creditCard'},
                                    {label: 'Efectivo', id: 'cash'},
                                    {label: 'Cheque', id: 'check'},
                                ]}
                                valueId="warrantyType"
                                value={userData.warrantyType}
                                onChangeValue={handleChange}
                                error={userErrors.warrantyType}
                            />
                        </div>
                    </div>
                    <div className="col-m6 col-s12 m-b">
                        <div className="flex-1">
                            <InputComponent
                                valueId="warranty"
                                value={userData.warranty}
                                onChangeValue={handleChange}
                                label="Valor de la garantía"
                                placeholder="Ejm: 2000"
                                icon={<SvgImport icon={moneyIcon} className="icon-16x" />}
                                error={userErrors.warranty}
                            />
                        </div>
                    </div>
                    {userData.warrantyType === 'check' &&
                        <>
                            <div className="col-m6 col-s12 m-b">
                                <div className="flex-1">
                                    <InputComponent
                                        valueId="checkNumber"
                                        value={userData.checkNumber}
                                        onChangeValue={handleChange}
                                        label="Número de cheque"
                                        placeholder="Ejm: 3443-3444-4453"
                                        icon={<SvgImport icon={accountnumberIcon} className="icon-16x" />}
                                        error={userErrors.checkNumber}
                                    />
                                </div>
                            </div>
                            <div className="col-m6 col-s12 m-b">
                                <div className="flex-1">
                                    <InputComponent
                                        valueId="checkBank"
                                        value={userData.checkBank}
                                        onChangeValue={handleChange}
                                        label="Banco emisor del cheque"
                                        placeholder="Ejm: 3443-3444-4453"
                                        icon={<SvgImport icon={bankIcon} className="icon-16x" />}
                                        error={userErrors.checkBank}
                                    />
                                </div>
                            </div>
                            <div className="col-m6 col-s12 m-b">
                                <div className="flex-1">
                                    <InputComponent
                                        valueId="checkBankAccount"
                                        value={userData.checkBankAccount}
                                        onChangeValue={handleChange}
                                        label="Numero de Cuenta"
                                        placeholder="Ejm: 11223344"
                                        icon={<SvgImport icon={accountnumberIcon} className="icon-16x" />}
                                        error={userErrors.checkBankAccount}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    </ModalLayoutComponent>
}

export default UserDocumentModal