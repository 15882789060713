import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IUserData {
  id: string
  name: string
  phone: string
  lastname: string
  document: string
  email: string
}

export interface IDocument {
  user: IUserData
  createAt: string
  contractDate: string
  city: string
  rentFromToDate: {
    startDate: string
    endDate: string
    key: string
  }
}

export interface ILocalDocuments extends IDocument{
  id: string
}

export interface IDocumentsReducer {
  documentsList: ILocalDocuments[]
}

const initialState: IDocumentsReducer = {
  documentsList: [],
}

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocuments: (state, action: PayloadAction<Array<ILocalDocuments>>) => {
      state.documentsList = action.payload || []
    },
  },
})

export const { setDocuments } = documentsSlice.actions

export default documentsSlice.reducer
